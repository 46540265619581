<template>
  <div>
    <b-form-group id="form" label="Form:" label-for="form">
      <b-form-input
        id="form"
        v-model="model.form"
        placeholder="Form"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="comment" label="Comment" label-for="comment">
      <b-form-input
        id="comment"
        v-model="model.comment"
        placeholder="Comment"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
import HelpStore from "@/store/helpstore.js";

export default {
  name: "BaseIndivNewEdit",
  props: ["baseindiv"],
  data() {
    return {
      model: this.baseindiv
        ? this.baseindiv
        : {
            id: null,
            form: "",
            comment: "",
          },
    };
  },
  mounted() {
    HelpStore.item = this.model;
  },
};
</script>
