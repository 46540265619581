<template>
  <div class="baseetymvars">
    <b-container fluid="xxl">
      <b-row>
        <b-col id="select-baseetymvar">
          <b-form-group
            id="baseetymvar"
            label="Base Etym Var:"
            label-for="baseetymvar"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>

          <b-form-group
            id="ordering"
            label="Ordering:"
            label-for="ordering"
            v-if="showOrdering"
          >
            <b-form-input
              id="ordering"
              v-model="ordering"
              placeholder="Ordering"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-new-base-etym-var" title="New Base Etym Var" ok-title="Save" @ok="saveNew" >
      <BaseEtymVarNewEdit :baseetymvar="{'form': this.query}" />
    </b-modal>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import BaseEtymVarNewEdit from "@/components/Scripta/BaseEtymVarNewEdit.vue";
import config from '@/config.js';

export default {
  name: "BaseEtymVarAutocomplete",
  props: ["exclude", "baseEtymVar", "showOrdering", "headId", "allowNew", "orderingNum", "parentId"],
  data() {
    return {
      query: "",
      timer: null,
      baseEtymVarObj: this.baseEtymVar,
      parentIdElem: this.parentId ? this.parentId : "select-baseetymvar",
      ordering: this.baseEtymVar ? this.baseEtymVar.ordering : (this.orderingNum ? this.orderingNum : 1),
      index: this.baseEtymVar ? this.baseEtymVar._index : null,
      showAddNew: this.allowNew == false ? false : true,
    };
  },
  components: {
    Select2,
    BaseEtymVarNewEdit,
  },
  methods: {
    select(event){
      if (event.selected && event.id == -2){
        this.$bvModal.show("modal-new-base-etym-var")
      }
      else {
        this.baseEtymVarObj = event.selected ? event.baseEtymVar : null
        this.$emit("select", this.baseEtymVarObj)
        this.update()
      }
    },
    saveNew(){
      this.$store.dispatch('createBaseetymvar', HelpStore.item).then((response) => {
        this.baseEtymVarObj = response.data
        this.$refs.select2.select2.append(
          `<option selected value="${this.baseEtymVarObj.id}">${this.baseEtymVarObj.form}</option>`
        ).trigger('change')
        HelpStore.item = this.baseEtymVarObj
      })
    },
    update(){
      if (this.baseEtymVarObj){
        this.baseEtymVarObj["ordering"] = this.ordering;
        this.baseEtymVarObj["_index"] = this.index;
        HelpStore.item = this.baseEtymVarObj;
      }
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.baseEtymVar){
      this.$refs.select2.select2.append(
        `<option selected value="${this.baseEtymVar.id}">${this.baseEtymVar.form}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select a base etym var",
        // allowClear: this.allowClear == false ? false : true,
        width: '100%',
        dropdownParent: `#${this.parentIdElem}`,
        templateSelection: (val) => {
          return val.form ? val.form : val.text
        },
        ajax: {
          delay: 250,
          url: config.apiUrl + '/baseetymvars/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            this.query = params.term
            return {
              query: params.term,
              // headId: this.headId,
              exclude: JSON.stringify(this.exclude),
            }
          },
          processResults: (data) => {
            if (data.length == 0 && this.showAddNew && this.query){
              data.push({
                id: -2,
                form: `Create new "${this.query}"`
              })
            }
            return {
              results: data.map(baseEtymVar => {
                let text = baseEtymVar.form
                let orderNum = 0
                if (baseEtymVar.id != -2 && !baseEtymVar.heads.map(head => head.id).includes(this.headId)) {
                  text = `${text} (not connected to head)`
                  orderNum = 1
                }
                return {
                  id: baseEtymVar.id,
                  text: text,
                  orderNum: orderNum,
                  form: baseEtymVar.form,
                  baseEtymVar: baseEtymVar
                }
              }).sort((a, b) => a.orderNum - b.orderNum)
            }
          }
        }
      }
    }
  },
};
</script>
