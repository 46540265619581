<template>
  <div class="evolutions">
    <b-container fluid="xxl">
      <b-row>
        <b-col id="select-evolution">
          <b-form-group
            id="evolution"
            label="Evolution:"
            label-for="evolution"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import config from '@/config.js';

export default {
  name: "EvolutionAutocomplete",
  props: ["exclude", "evolution", "parentId"],
  data() {
    return {
      query: "",
      timer: null,
      parentIdElem: this.parentId ? this.parentId : "select-evolution",
      evolutionObj: this.evolution,
      index: this.evolution ? this.evolution._index : null,
    };
  },
  components: {
    Select2,
  },
  methods: {
    select(event){
      this.evolutionObj = event.selected ? event.evolution : null
      this.$emit("select", this.evolutionObj)
      this.update()
    },
    update(){
      this.evolutionObj["_index"] = this.index;
      HelpStore.item = this.evolutionObj;
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.evolution){
      this.$refs.select2.select2.append(
        `<option selected value="${this.evolution.id}">${this.evolution.form}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select an evolution",
        allowClear: this.allowClear == false ? false : true,
        width: '100%',
        dropdownParent: `#${this.parentIdElem}`,
        ajax: {
          delay: 250,
          url: config.apiUrl + '/evolutions/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            return {
              query: params.term,
              exclude: JSON.stringify(this.exclude),
            }
          },
          processResults: (data) => {
            return {
              results: data.map(evolution => {
                return {
                  id: evolution.id,
                  text: evolution.form,
                  evolution: evolution
                }
              })
            }
          }
        }
      }
    }
  },
};
</script>
