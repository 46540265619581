<template>
  <div class="basespecials">
    <b-container fluid="xxl">
      <b-row>
        <b-col id="select-basespecial">
          <b-form-group
            id="basespecial"
            label="Base Special:"
            label-for="basespecial"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>

          <b-form-group
            id="ordering"
            label="Ordering:"
            label-for="ordering"
            v-if="showOrdering"
          >
            <b-form-input
              id="ordering"
              v-model="ordering"
              placeholder="Ordering"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-new-base-special" title="New Base Special" ok-title="Save" @ok="saveNew" >
      <BaseSpecialNewEdit :basespecial="{'form': this.query}" />
    </b-modal>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import BaseSpecialNewEdit from "@/components/Scripta/BaseSpecialNewEdit.vue";
import config from '@/config.js';

export default {
  name: "BaseSpecialAutocomplete",
  props: ["exclude", "baseSpecial", "showOrdering", "headId", "orderingNum", "parentId"],
  data() {
    return {
      query: "",
      timer: null,
      baseSpecialObj: this.baseSpecial,
      parentIdElem: this.parentId ? this.parentId : "select-basespecial",
      ordering: this.baseSpecial ? this.baseSpecial.ordering : (this.orderingNum ? this.orderingNum : 1),
      index: this.baseSpecial ? this.baseSpecial._index : null,
      showAddNew: this.allowNew == false ? false : true,
    };
  },
  components: {
    Select2,
    BaseSpecialNewEdit,
  },
  methods: {
    select(event){
      if (event.selected && event.id == -2){
        this.$bvModal.show("modal-new-base-special")
      }
      else {
        this.baseSpecialObj = event.selected ? event.baseSpecial : null
        this.$emit("select", this.baseSpecialObj)
        this.update()
      }
    },
    saveNew(){
      this.$store.dispatch('createBasespecial', HelpStore.item).then((response) => {
        this.baseSpecialObj = response.data
        this.$refs.select2.select2.append(
          `<option selected value="${this.baseSpecialObj.id}">${this.baseSpecialObj.form}</option>`
        ).trigger('change')
        HelpStore.item = this.baseSpecialObj
      })
    },
    update(){
      if (this.baseSpecialObj){
        this.baseSpecialObj["ordering"] = this.ordering;
        this.baseSpecialObj["_index"] = this.index;
        HelpStore.item = this.baseSpecialObj;
      }
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.baseSpecial){
      this.$refs.select2.select2.append(
        `<option selected value="${this.baseSpecial.id}">${this.baseSpecial.form}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select a base special",
        // allowClear: this.allowClear == false ? false : true,
        width: '100%',
        dropdownParent: `#${this.parentIdElem}`,
        templateSelection: (val) => {
          return val.form ? val.form : val.text
        },
        ajax: {
          delay: 250,
          url: config.apiUrl + '/basespecials/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            this.query = params.term
            return {
              query: params.term,
              // headId: this.headId,
              exclude: JSON.stringify(this.exclude),
            }
          },
          processResults: (data) => {
            if (data.length == 0 && this.showAddNew && this.query){
              data.push({
                id: -2,
                form: `Create new "${this.query}"`
              })
            }
            return {
              results: data.map(baseSpecial => {
                let text = baseSpecial.form
                let orderNum = 0
                if (baseSpecial.id != -2 && !baseSpecial.heads.map(head => head.id).includes(this.headId)) {
                  text = `${text} (not connected to head)`
                  orderNum = 1
                }
                return {
                  id: baseSpecial.id,
                  text: text,
                  orderNum: orderNum,
                  form: baseSpecial.form,
                  baseSpecial: baseSpecial
                }
              }).sort((a, b) => a.orderNum - b.orderNum)
            }
          }
        }
      }
    }
  },
};
</script>
