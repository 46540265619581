<template>
  <div id="hcc-parent-element">
    <PhonolEnvAutocomplete
      @select="model.phonolEnv = $event"
      :phonolEnv="model.phonolEnv"
      :key="'p-' + index"
      parentId='hcc-parent-element'
    />
    <BaseEtymAutocomplete
      @select="model.baseEtym = $event"
      :baseEtym="model.baseEtym"
      :key="'be-' + index"
      parentId='hcc-parent-element'
    />
    <BaseEtymVarAutocomplete
      @select="model.baseEtymVar = $event"
      :baseEtymVar="model.baseEtymVar"
      :key="'bev-' + index"
      parentId='hcc-parent-element'
    />
    <BaseIndivAutocomplete
      @select="model.baseIndiv = $event"
      :baseIndiv="model.baseIndiv"
      :key="'bi-' + index"
      parentId='hcc-parent-element'
    />
    <BaseSpecialAutocomplete
      @select="model.baseSpecial = $event"
      :baseSpecial="model.baseSpecial"
      :key="'bs-' + index"
      parentId='hcc-parent-element'
    />
    <EvolutionAutocomplete
      @select="model.evolution = $event"
      :evolution="model.evolution"
      :key="'e-' + index"
      parentId='hcc-parent-element'
    />
    <IssueTypeAutocomplete
      @select="model.issueType = $event"
      :issueType="model.issueType"
      :key="'it-' + index"
      parentId='hcc-parent-element'
    />
    <IssueVarAutocomplete
      @select="model.issueVar = $event"
      :issueVar="model.issueVar"
      :key="'iv-' + index"
      parentId='hcc-parent-element'
    />
    <IssuePhonAutocomplete
      @select="model.issuePhon = $event"
      :issuePhon="model.issuePhon"
      :key="'ip-' + index"
      parentId='hcc-parent-element'
    />

    <div class="mt-5 mb-3">
      Selected head: <b v-html="HCCCode"></b>
      <div v-if="checkAlreadyExists && !hccId" style="color: red" class="mt-1">
        HCC already exists
      </div>
    </div>

    <b-form-group
      id="description-group"
      label="Description (shortname)"
      label-for="description"
    >
      <b-form-input
        id="description"
        v-model="model.description"
        placeholder="Description"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="examplesLatin-group"
      label="Examples Latin"
      label-for="examplesLatin"
    >
      <b-form-input
        id="examplesLatin"
        v-model="model.examplesLatin"
        placeholder="Examples Latin"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="examplesVernacular-group"
      label="Examples Vernacular"
      label-for="examplesVernacular"
    >
      <b-form-input
        id="examplesVernacular"
        v-model="model.examplesVernacular"
        placeholder="Examples Vernacular"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="comment-group" label="Comment" label-for="comment">
      <b-form-input
        id="comment"
        v-model="model.comment"
        placeholder="Comment"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<style scoped>
* >>> .container-xxl {
  padding-left: 0;
}
</style>

<script>
import { mapState } from "vuex";
import HelpStore from "@/store/helpstore.js";
import PhonolEnvAutocomplete from "@/components/Scripta/PhonolEnvAutocomplete.vue";
import BaseEtymAutocomplete from "@/components/Scripta/BaseEtymAutocomplete.vue";
import BaseEtymVarAutocomplete from "@/components/Scripta/BaseEtymVarAutocomplete.vue";
import BaseIndivAutocomplete from "@/components/Scripta/BaseIndivAutocomplete.vue";
import BaseSpecialAutocomplete from "@/components/Scripta/BaseSpecialAutocomplete.vue";
import EvolutionAutocomplete from "@/components/Scripta/EvolutionAutocomplete.vue";
import IssueTypeAutocomplete from "@/components/Scripta/IssueTypeAutocomplete.vue";
import IssueVarAutocomplete from "@/components/Scripta/IssueVarAutocomplete.vue";
import IssuePhonAutocomplete from "@/components/Scripta/IssuePhonAutocomplete.vue";

//                      ┌─────────────────────────────┐        ┌─────────────────────┐
//                      │            base             │        │        issue        │
// ┌──────┐   ┌───────┐ ├────┬────────┬─────┬─────────┤ ┌────┐ ├────┬───────┬────────┤
// │ head ├───┤environ├─┤etym│etym-var│indiv│spec-cond├─┤evol├─┤type│variant│phon-int│
// └──────┘   └───────┘ └────┴────────┴─────┴─────────┘ └────┘ └────┴───────┴────────┘

export default {
  name: "HCCCreate",
  props: ["head", "hccId", "hccFromId"],
  data() {
    return {
      checkAlreadyExists: false,
      index: 0,
      model: {
        description: "",
        examplesLatin: "",
        examplesVernacular: "",
        comment: "",
        head: this.head,
        selected: [],
        phonolEnv: null,
        baseEtym: null,
        baseEtymVar: null,
        baseIndiv: null,
        baseSpecial: null,
        evolution: null,
        issueType: null,
        issueVar: null,
        issuePhon: null,
      },
    };
  },
  components: {
    PhonolEnvAutocomplete,
    BaseEtymAutocomplete,
    BaseEtymVarAutocomplete,
    BaseIndivAutocomplete,
    BaseSpecialAutocomplete,
    EvolutionAutocomplete,
    IssueTypeAutocomplete,
    IssueVarAutocomplete,
    IssuePhonAutocomplete,
  },
  watch: {
    model() {
      HelpStore.item = this.model;
    },
    hcc() {
      let hcc = this.hcc
      if (this.hccFromId){
        hcc.id = null
      }
      this.model = hcc;
      this.index++;
    },
  },
  computed: {
    getHeadId() {
      let headId = this.head ? this.head.id : null
      if (headId == null && this.model && this.model.head){
        headId = this.model.head.id
      }
      return headId
    },
    ...mapState({
      hcc: (state) => state.hcc.hcc,
    }),
    HCCCode() {
      let form = this.head ? this.head.form : "";
      let phonolEnv = this.model.phonolEnv ? this.model.phonolEnv.form : "";
      let baseEtym = this.model.baseEtym ? this.model.baseEtym.form : "";
      let baseEtymVar = this.model.baseEtymVar
        ? this.model.baseEtymVar.form
        : "";
      let baseIndiv = this.model.baseIndiv ? this.model.baseIndiv.form : "";
      let baseSpecial = this.model.baseSpecial
        ? this.model.baseSpecial.form
        : "";
      let evolution = this.model.evolution ? this.model.evolution.form : "";
      let issueType = this.model.issueType ? this.model.issueType.form : "";
      let issueVar = this.model.issueVar ? this.model.issueVar.form : "";
      let issuePhon = this.model.issuePhon ? this.model.issuePhon.form : "";
      return `${form}=${phonolEnv}.${baseEtym}-${baseEtymVar}-${baseIndiv}-${baseSpecial}.${evolution}.${issueType}-${issueVar}.${issuePhon}`;
    },
  },
  methods: {
    validate() {
      HelpStore.item = this.model;
      return (
        this.model.description.length > 0 &&
        this.model.phonolEnv != null &&
        this.model.baseEtym != null &&
        this.model.baseEtymVar != null &&
        this.model.baseIndiv != null &&
        this.model.baseSpecial != null &&
        this.model.evolution != null &&
        this.model.issueType != null &&
        this.model.issueVar != null &&
        this.model.issuePhon != null &&
        (this.hccId || !this.checkAlreadyExists)
      );
    },
  },
  updated() {
    this.$store.dispatch("checkHCCExists", this.model).then((data) => {
      this.checkAlreadyExists = data.data.result;
    });
    HelpStore.item = this.model;
  },
  mounted() {
    if (this.hccId) {
      this.$store.dispatch("loadHCC", this.hccId);
    }
    if (this.hccFromId) {
      this.$store.dispatch("loadHCC", this.hccFromId);
    }
    HelpStore.item = this.model;
  },
};
</script>
