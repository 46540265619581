<template>
  <div class="issuephons">
    <b-container fluid="xxl">
      <b-row>
        <b-col id="select-issuephon">
          <b-form-group
            id="issuephon"
            label="Issue Phon:"
            label-for="issuephon"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>

          <b-form-group
            id="ordering"
            label="Ordering:"
            label-for="ordering"
            v-if="showOrdering"
          >
            <b-form-input
              id="ordering"
              v-model="ordering"
              placeholder="Ordering"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-new-issue-phon" title="New Issue Phone" ok-title="Save" @ok="saveNew" >
      <IssuePhonNewEdit :issuephon="{'form': this.query}" />
    </b-modal>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import IssuePhonNewEdit from "@/components/Scripta/IssuePhonNewEdit.vue";
import config from '@/config.js';

export default {
  name: "IssuePhonAutocomplete",
  props: ["exclude", "issuePhon", "showOrdering", "headId", "orderingNum", "parentId"],
  data() {
    return {
      query: "",
      timer: null,
      issuePhonObj: this.issuePhon,
      parentIdElem: this.parentId ? this.parentId : "select-issuephon",
      ordering: this.issuePhon ? this.issuePhon.ordering : (this.orderingNum ? this.orderingNum : 1),
      index: this.issuePhon ? this.issuePhon._index : null,
      showAddNew: this.allowNew == false ? false : true,
    };
  },
  components: {
    Select2,
    IssuePhonNewEdit,
  },
  methods: {
    select(event){
      if (event.selected && event.id == -2){
        this.$bvModal.show("modal-new-issue-phon")
      }
      else {
        this.issuePhonObj = event.selected ? event.issuePhon : null
        this.$emit("select", this.issuePhonObj)
        this.update()
      }
    },
    saveNew(){
      this.$store.dispatch('createIssuephon', HelpStore.item).then((response) => {
        this.issuePhonObj = response.data
        this.$refs.select2.select2.append(
          `<option selected value="${this.issuePhonObj.id}">${this.issuePhonObj.form}</option>`
        ).trigger('change')
        HelpStore.item = this.issuePhonObj
      })
    },
    update(){
      if (this.issuePhonObj){
        this.issuePhonObj["ordering"] = this.ordering;
        this.issuePhonObj["_index"] = this.index;
        HelpStore.item = this.issuePhonObj;
      }
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.issuePhon){
      this.$refs.select2.select2.append(
        `<option selected value="${this.issuePhon.id}">${this.issuePhon.form}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select an issue phone",
        // allowClear: this.allowClear == false ? false : true,
        width: '100%',
        dropdownParent: `#${this.parentIdElem}`,
        templateSelection: (val) => {
          return val.form ? val.form : val.text
        },
        ajax: {
          delay: 250,
          url: config.apiUrl + '/issuephons/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            this.query = params.term
            return {
              query: params.term,
              // headId: this.headId,
              exclude: JSON.stringify(this.exclude),
            }
          },
          processResults: (data) => {
            if (data.length == 0 && this.showAddNew && this.query){
              data.push({
                id: -2,
                form: `Create new "${this.query}"`
              })
            }
            return {
              results: data.map(issuePhon => {
                let text = issuePhon.form
                let orderNum = 0
                if (issuePhon.id != -2 && !issuePhon.heads.map(head => head.id).includes(this.headId)) {
                  text = `${text} (not connected to head)`
                  orderNum = 1
                }
                return {
                  id: issuePhon.id,
                  text: text,
                  orderNum: orderNum,
                  form: issuePhon.form,
                  issuePhon: issuePhon
                }
              }).sort((a, b) => a.orderNum - b.orderNum)
            }
          }
        }
      }
    }
  },
};
</script>
